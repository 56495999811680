import { useLoadScript} from '@react-google-maps/api';
import { APIProvider, Map, AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import {useState} from "react";

const MapComponent = () =>{
  const [open, setOpen] = useState(false);  

  // The address and location of the business.
  const location = {
    address: "1134 Taraval St, San Francisco, CA 94116",
    lat: 37.743170,
    lng: -122.478470,
  };

    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: "AIzaSyADuqRsq5AuNQtgnrUE8HPuW9SqfuxInfc",  
    });

    if (loadError){
      return <div>
        <p>Error loading Map.</p>
      </div>
    }

  if (!isLoaded){
    return <div>
      <p> Loading Map... </p>
    </div>
  }

  
  return(
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <div style={{height:"400px"}}>
        <Map defaultZoom={14} defaultCenter={location} mapId="24b407a784b94223">
          <AdvancedMarker position={location} onClick={() => setOpen(true)} />
        </Map>
        {open && (<InfoWindow position={location} onCloseClick={() => setOpen(false)}><p>Parkside Dental Care</p><p>1134 Taraval St</p><p>San Francisco, CA 94116</p></InfoWindow>)}
      </div>
    </APIProvider>
  )
};

export default MapComponent;
