import React from 'react';
import './Schedule.css'
import MapComponent from '../components/MapComponent'

function Schedule() {
  return (
    <>
      <div className='schedule-container'>
        <div className="column left-column">
          <MapComponent />
        </div>
        <div className="column right-column">
          <h1>Street Address</h1>
            <br></br>
            <p>Parkside Dental Care</p>
            <p>1134 Taraval Street</p>
            <p>San Francisco, California 94116</p>
            <ul className='schedule-list'>
              <center>
              <h1>Schedule</h1>
              <br></br>
              <li className='day'><b>Monday</b>: 9 AM - 6 PM</li>
              <li className='day'><b>Tuesday</b>: 9 AM - 6 PM</li>
              <li className='day'><b>Wednesday</b>: Closed</li>
              <li className='day'><b>Thursday</b>: 9 AM - 6 PM</li>
              <li className='day'><b>Friday</b>: 9 AM - 6 PM</li>
              <li className='day'><b>Saturday</b>: 9 AM - 2 PM</li>
              <li className='day'><b>Sunday</b>: Closed</li>
              </center>
            </ul>  
          </div>
      </div>
    </>
  )
}

export default Schedule