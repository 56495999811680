import React from 'react';
import './ServicesTable.css';

function ServicesTable() {
    return (
        <>
            <div>
                <table class="center">
                    <tbody>
                        <th class="th-larger">
                            Cleanings and Preventions
                        </th>
                        <tr>
                            Dental Exams & Cleanings
                        </tr>
                        <tr>
                            Dental X-Rays
                        </tr>
                        <tr>
                            Flouride Treatment
                        </tr>
                        <tr>
                            Sealants
                        </tr>
                        <tr>&nbsp;</tr>
                        <th class="th-larger">
                            Cosmetic Dentistry
                        </th>
                        <tr>
                            Porcelain Crowns (Caps)
                        </tr>
                        <tr>
                            Porcelain Fixed Bridges
                        </tr>
                        <tr>
                            Porcelain Veneers
                        </tr>
                        <tr>
                            Tooth Whitening
                        </tr>
                        <tr>&nbsp;</tr>
                        <th class= "th-larger">
                            Restorations
                        </th>
                        <tr>
                            Composite Fillings
                        </tr>
                        <tr>
                            Amalgam Fillings
                        </tr>
                        <tr>
                            Dental Implants
                        </tr>
                        <tr>
                            Crowns (Caps)
                        </tr>
                        <tr>
                            Dentures & Partial Dentures
                        </tr>
                        <tr>
                            Fixed Bridges
                        </tr>
                        <tr>
                            Root Canal Therapy
                        </tr>
                    </tbody>
                </table>                
            </div>
        </>
    );
}

export default ServicesTable;

