import React from 'react';
import './Services.css';
import ServicesTable from '../components/ServicesTable';

function Services() {
  return (
    <>
      <div className='services-container'>
        <h1>Available Services</h1><br></br>
        <p>Most dental insurance plans accepted.</p>
        <ServicesTable />
      </div>
    </>
  )
}

export default Services;
