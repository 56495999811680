import React from 'react'
import './Contact.css'

function Contact() {
  return (
    <div className="contact-container">
        <h1>Contact Us</h1>
        <br></br>
        <p>Make an appointment or ask questions by phone:
            <br></br>
            <p>
            <a href="tel:+4155718827"> 
                (415)571-8827
            </a>
            </p>
        </p>

        <br></br>
    </div>
  )
}

export default Contact