import React from 'react';
import { Link } from 'react-router-dom';
import "./Home.css";
import storefront from '../assets/images/storefront.png';
import '../components/GreenButton.css';

function Home() {
  return (
    <>
    <div className="container">
      <div className="column left-column">
        <h1>Welcome To Parkside Dental Care.</h1><br></br>
        <p>Located in the heart of the Parkside District, the staff at Parkside Dental Care has been dedicated to providing personalized dental services for the community since 2007. The office is on 1134 Taraval Street, and is easily accessible via the L Muni.</p>
        <p>Using state-of-the-art technology to ensure your greatest dental health, Parkside Dental Care offers a comprehensive range of services, from cleanings and preventions, to restorative treatments, to cosmetic dentistry. </p>
        <Link to="/contact">
          <button className="btn btn-success green-btn">Contact Us</button>
        </Link>
     </div>
      <div className="column right-column">
        <img src={ storefront } alt="The storefront of Parkside Dental Care."/>
      </div>
    </div>
    </>
  )
}

export default Home