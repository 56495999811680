import React from 'react'
import './Navigation.css'
import logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom';

function Navigation() {
  return (
    <>
        <nav className='navi'>
            <Link to="/" className='logo'> <img src={logo} alt="logo" margin-left="15px" height="70px" /></Link>
            <ul className='navi-items'>
                <li className = 'item'>
                    <Link to="/" className="active">Home</Link>
                </li>
                <li className = 'item'>
                    <Link to="/schedule" className="active">Schedule</Link>
                </li>
                <li className = 'item'>
                    <Link to="/services" className="active">Services</Link>
                </li>
                <li className = 'item'>
                    <Link to="/contact" className="active">Contact</Link>
                </li>
            </ul>
        </nav>
    </>
  )
}

export default Navigation